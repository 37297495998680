import React from 'react'
//import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderRichText } from "gatsby-source-contentful/rich-text"
//const ReachText = ({ raw }) => {
//    return (
//        <div>{documentToReactComponents(JSON.parse(raw))}</div>)
//}
//export default ReachText;

const ReachText = ( data ) => {
   
    return (
        <div>{renderRichText(data)}</div>)
}
export default ReachText;